import { styled } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: '#fff',
  zIndex: 1200,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '1rem',
}));

export const NavigationOverlay = styled('div')({
  position: 'relative',
  width: 'fit-content',
  height: 'fit-content',
  background: '#fff',
  overflow: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const DiagramImage = styled('img')({
  maxWidth: '100%',
  maxHeight: '100%',
  objectFit: 'contain',
});

export const RowLines = styled('div')<{ 
  rowCount: number;
  top: number;
  bottom: number;
}>(({ rowCount, top, bottom }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  pointerEvents: 'none',

  '&::before': {
    content: '""',
    position: 'absolute',
    top: `${top}%`,
    left: 0,
    width: '100%',
    height: `${bottom - top}%`,
    background: Array(rowCount - 1)
      .fill('')
      .map((_, i) => {
        const percentage = ((i + 1) * 100) / rowCount;
        return `linear-gradient(
          transparent calc(${percentage}% - 0.5px),
          rgba(255, 0, 0, 0.5) calc(${percentage}% - 0.5px),
          rgba(255, 0, 0, 0.5) calc(${percentage}% + 0.5px),
          transparent calc(${percentage}% + 0.5px)
        )`
      })
      .join(','),
  }
}));

export const InputContainer = styled('div')({
  position: 'absolute',
  top: '4vh',
  left: '23vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '1vh',
  zIndex: 2,
});

export const InputLabel = styled('label')({
  color: '#000',
  fontSize: '2vh',
  fontWeight: 'bold',
});

export const InstructionText = styled('div')({
  color: '#666',
  fontSize: '1.8vh',
  textAlign: 'center',
  maxWidth: '25vh',
  marginTop: '1vh',
});

export const RowCountInput = styled('input')({
  padding: '1vh',
  width: '15vh',
  height: '8vh',
  fontSize: '3vh',
  textAlign: 'center',
  border: '1px solid #000',
  borderRadius: '4px',
});

export const CollapseButton = styled('button')(({ theme }) => ({
  position: 'absolute',
  top: '4vh',
  right: '4vh',
  padding: '4vh',
  width: '15vh',
  height: '15vh',
  background: 'rgba(255, 255, 255, 0.9)',
  border: '1px solid #000',
  borderRadius: '50%',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1,
  
  '& svg': {
    fontSize: '8vh',
  },

  '&:hover': {
    background: '#F6F4ED',
  },
}));

export const BoundaryLine = styled('div')<{ 
  $dragging?: boolean;
  $position: 'top' | 'bottom';
}>(({ $dragging, $position }) => ({
  position: 'absolute',
  left: 0,
  width: '100%',
  height: '1px',
  background: '#0066ff',
  cursor: 'ns-resize',
  opacity: $dragging ? 0.8 : 0.5,
  transition: 'opacity 0.2s ease',
  
  '&:hover': {
    opacity: 0.8,
  },

  '&::after': {
    content: '""',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '20px',
    height: '20px',
    background: '#0066ff',
    borderRadius: '50%',
    top: $position === 'top' ? '-8px' : '-8px',
  }
}));
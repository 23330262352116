import { useInputWithDelay } from '@/hooks/useInputDelay.hook';
import { getAssetBackendUrl } from '@/utils/getAssetBackendUrl';
import { ChangeEvent, FC, useRef, useState } from 'react';
import { Input } from '../input/input';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { useTranslation } from 'react-i18next';
import {
  ImageContainer,
  Name,
  Container,
  DeleteButton,
  ImgEl,
  PlaceholderImage,
  EanPlaceholderImage,
  RemovingOverlay,
  PlaceholderText,
  OrBrowseText,
} from './styles';
import { Button } from '../button/button';
import { CircularProgress } from '@mui/material';
import Resizer from 'react-image-file-resizer';
import { stripFileExtension } from '@/utils/stripFileExtension';

const resizeFile = (file: Blob): Promise<File> =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      4000,
      4000,
      'JPEG',
      100,
      0,
      (uri) => {
        resolve(uri as File);
      },
      'file',
    );
  });

interface Props {
  name?: string;
  ean?: string;
  colorImage?: string | null;
  eanImage?: string | null;
  readonly?: boolean;
  isLoading: boolean;
  onSave?: (color: { image?: File; eanImage?: File; ean?: string; name?: string }) => void;
  onRemoveColorImage?: () => void;
  onRemoveEanImage?: () => void;
  onRemove?: () => void;
}

const MAX_IMAGE_DIMENSION = parseInt(process.env.REACT_APP_MAX_IMAGE_DIMENSION || '6000', 10);

export const YarnColorAndEanComponent: FC<Props> = ({
  name,
  ean,
  colorImage,
  eanImage,
  readonly = false,
  isLoading,
  onSave = () => { },
  onRemoveColorImage = () => { },
  onRemoveEanImage = () => { },
  onRemove = () => { },
}) => {
  const { t } = useTranslation();

  const colorImageUploadRef = useRef<HTMLInputElement>(null);
  const eanImageUploadRef = useRef<HTMLInputElement>(null);
  const [colorImageUrl, setColorImageUrl] = useState<string | Blob | null>(colorImage || null);
  const [eanImageUrl, setEanImageUrl] = useState<string | Blob | null>(eanImage || null);
  const [isRemoving, setIsRemoving] = useState(false);
  const [isDraggingColor, setIsDraggingColor] = useState(false);
  const [isDraggingEan, setIsDraggingEan] = useState(false);
  const [isUploadingColor, setIsUploadingColor] = useState(false);
  const [isUploadingEan, setIsUploadingEan] = useState(false);

  const handleSave = () => {
    const saveData: {
      ean?: string;
      name?: string;
    } = {};

    if (eanNumber !== ean) {
      saveData.ean = eanNumber;
    }

    if (title !== name) {
      saveData.name = title;
    }

    // Only call onSave if there are actual changes
    if (Object.keys(saveData).length > 0) {
      onSave(saveData);
    }
  };

  const [title, setTitle] = useInputWithDelay(name!, handleSave, 500);
  const handleChangeTitle = (value: string) => setTitle(value);

  const [eanNumber, setEanNumber] = useInputWithDelay(ean!, handleSave, 500);
  const handleChangeEanNumber = (value: string) => setEanNumber(value);

  const handleRemoveColorImage = () => {
    setColorImageUrl(null);
    onRemoveColorImage();
  };

  const handleRemoveEanImage = () => {
    setEanImageUrl(null);
    onRemoveEanImage();
  };

  const handleClickUploadColorImage = () => {
    if (readonly) return;
    colorImageUploadRef.current?.click();
  };

  const handleClickUploadEanImage = () => {
    if (readonly) return;
    eanImageUploadRef.current?.click();
  };

  const handleFileUpload = async (file: File, isEan: boolean) => {
    if (readonly) return;

    const img = new window.Image();
    const objectUrl = URL.createObjectURL(file);

    try {
      isEan ? setIsUploadingEan(true) : setIsUploadingColor(true);

      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
        img.src = objectUrl;
      });

      if (img.width > MAX_IMAGE_DIMENSION || img.height > MAX_IMAGE_DIMENSION) {
        const resizedImage = await resizeFile(file);
        const fileName = stripFileExtension(resizedImage.name);

        if (isEan) {
          const hasEan = ean && ean.length > 0;
          if (!hasEan) setEanNumber(fileName);
          setEanImageUrl(resizedImage);
          onSave({ eanImage: resizedImage, ...(!hasEan && { ean: fileName }) });
        } else {
          const hasName = name && name.length > 0;
          if (!hasName) setTitle(fileName);
          setColorImageUrl(resizedImage);
          onSave({ image: resizedImage, ...(!hasName && { name: fileName }) });
        }
      } else {
        const fileName = stripFileExtension(file.name);

        if (isEan) {
          const hasEan = ean && ean.length > 0;
          if (!hasEan) setEanNumber(fileName);
          setEanImageUrl(file);
          onSave({ eanImage: file, ...(!hasEan && { ean: fileName }) });
        } else {
          const hasName = name && name.length > 0;
          if (!hasName) setTitle(fileName);
          setColorImageUrl(file);
          onSave({ image: file, ...(!hasName && { name: fileName }) });
        }
      }
    } finally {
      URL.revokeObjectURL(objectUrl);
      isEan ? setIsUploadingEan(false) : setIsUploadingColor(false);
    }
  };

  const handleChangeColorImage = async ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (readonly || !target.files?.[0]) return;
    await handleFileUpload(target.files[0], false);
    target.value = '';
  };

  const handleChangeEanImage = async ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (readonly || !target.files?.[0]) return;
    await handleFileUpload(target.files[0], true);
    target.value = '';
  };

  const handleDragOver = (e: React.DragEvent, isEan: boolean) => {
    e.preventDefault();
    e.stopPropagation();
    if (!readonly) {
      if (isEan) {
        setIsDraggingEan(true);
      } else {
        setIsDraggingColor(true);
      }
    }
  };

  const handleDragLeave = (e: React.DragEvent, isEan: boolean) => {
    e.preventDefault();
    e.stopPropagation();
    if (isEan) {
      setIsDraggingEan(false);
    } else {
      setIsDraggingColor(false);
    }
  };

  const handleDrop = async (e: React.DragEvent, isEan: boolean) => {
    e.preventDefault();
    e.stopPropagation();

    setIsDraggingColor(false);
    setIsDraggingEan(false);

    if (readonly) return;

    const file = e.dataTransfer.files[0];
    if (file && file.type.startsWith('image/')) {
      await handleFileUpload(file, isEan);
    }
  };

  return (
    <Container>
      {colorImageUrl ? (
        <ImageContainer>
          {!readonly && (
            <DeleteButton
              onClick={(e) => {
                e.stopPropagation();
                handleRemoveColorImage();
              }}
              disabled={isLoading}
            >
              {t('common.remove')}
            </DeleteButton>
          )}
          <div style={{ position: 'relative' }}>
            <ImgEl
              src={
                typeof colorImageUrl === 'string'
                  ? getAssetBackendUrl(colorImageUrl, 900)
                  : URL.createObjectURL(colorImageUrl)
              }
              onClick={handleClickUploadColorImage}
              style={{
                cursor: readonly ? 'auto' : 'pointer',
                opacity: isUploadingColor ? 0.5 : 1,
              }}
            />
            {isUploadingColor && (
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <CircularProgress style={{ color: '#000' }} size={24} />
              </div>
            )}
          </div>
        </ImageContainer>
      ) : (
        <PlaceholderImage
          onClick={handleClickUploadColorImage}
          style={{ cursor: readonly ? 'auto' : 'pointer' }}
          isDragging={isDraggingColor}
          onDragOver={(e) => handleDragOver(e, false)}
          onDragLeave={(e) => handleDragLeave(e, false)}
          onDrop={(e) => handleDrop(e, false)}
          onDragEnter={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (!readonly) setIsDraggingColor(true);
          }}
        >
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              pointerEvents: 'none',
            }}
          >
            {isUploadingColor ? (
              <CircularProgress style={{ color: '#000' }} size={24} />
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                <CloudUploadIcon style={{ fontSize: 40, color: '#666' }} />
                <PlaceholderText>{t('Yarniverse.uploadImage')}</PlaceholderText>
                <OrBrowseText>({t('Yarniverse.orBrowse')})</OrBrowseText>
              </div>
            )}
          </div>
        </PlaceholderImage>
      )}
      <input
        type='file'
        ref={colorImageUploadRef}
        accept='image/*'
        name={'colorImage'}
        onChange={handleChangeColorImage}
        hidden
      />
      {readonly ? (
        <Name>{name}</Name>
      ) : (
        <Input
          placeholder={t('Yarniverse.nameOfColor')}
          value={title}
          onChange={(e) => handleChangeTitle(e.target.value)}
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: '#000000',
            },
            '&:before': {
              borderBottomStyle: 'solid !important',
            },
            fontSize: '1.2rem',
          }}
        />
      )}
      <div style={{ minHeight: '16px' }} />
      <input
        type='file'
        ref={eanImageUploadRef}
        accept='image/*'
        name={'eanImage'}
        onChange={handleChangeEanImage}
        hidden
      />
      {eanImageUrl ? (
        <ImageContainer>
          {!readonly && (
            <DeleteButton
              onClick={(e) => {
                e.stopPropagation();
                handleRemoveEanImage();
              }}
              disabled={isLoading}
            >
              {t('common.remove')}
            </DeleteButton>
          )}
          <div style={{ position: 'relative' }}>
            <ImgEl
              src={
                typeof eanImageUrl === 'string'
                  ? getAssetBackendUrl(eanImageUrl)
                  : URL.createObjectURL(eanImageUrl)
              }
              onClick={handleClickUploadEanImage}
              style={{
                cursor: readonly ? 'auto' : 'pointer',
                opacity: isUploadingEan ? 0.5 : 1,
              }}
            />
            {isUploadingEan && (
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <CircularProgress style={{ color: '#000' }} size={24} />
              </div>
            )}
          </div>
        </ImageContainer>
      ) : (
        <EanPlaceholderImage
          onClick={handleClickUploadEanImage}
          style={{ cursor: readonly ? 'auto' : 'pointer' }}
          isDragging={isDraggingEan}
          onDragOver={(e) => handleDragOver(e, true)}
          onDragLeave={(e) => handleDragLeave(e, true)}
          onDrop={(e) => handleDrop(e, true)}
          onDragEnter={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (!readonly) setIsDraggingEan(true);
          }}
        >
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              pointerEvents: 'none',
            }}
          >
            <div
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px' }}
            >
              <CloudUploadIcon style={{ fontSize: 40, color: '#666' }} />
              <PlaceholderText>{t('Yarniverse.uploadEanImage')}</PlaceholderText>
              <OrBrowseText>({t('Yarniverse.orBrowse')})</OrBrowseText>
            </div>
          </div>
        </EanPlaceholderImage>
      )}
      {readonly ? (
        <Name>{ean}</Name>
      ) : (
        <Input
          placeholder={t('Yarniverse.eanNumber')}
          value={eanNumber}
          onChange={(e) => handleChangeEanNumber(e.target.value)}
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: '#000000',
            },
            '&:before': {
              borderBottomStyle: 'solid !important',
            },
            fontSize: '1.2rem',
          }}
        />
      )}

      {!readonly && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Button
            remove
            style={{ marginTop: '16px' }}
            onClick={() => {
              setIsRemoving(true);
              onRemove();
            }}
            disabled={isRemoving || isLoading}
          >
            {t('common.remove')}
          </Button>
        </div>
      )}
      {isRemoving && (
        <RemovingOverlay>
          <CircularProgress style={{ color: '#000' }} size={24} />
        </RemovingOverlay>
      )}
    </Container>
  );
};

import { useState, useEffect } from 'react';
import  useDirectus  from '@/hooks/directus/useDirectus'

interface PageData {
  id: string;
  title: string;
  content: string;
}

interface UsePageDataOptions {
  multiple?: boolean; // Fetch multiple rows if true
}

const usePageData = (pageName: string, options?: UsePageDataOptions) => {
  const { list } = useDirectus();
  const [pageData, setPageData] = useState<PageData[] | PageData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchPageData = async () => {
      try {
        setLoading(true);
        const query = {
          fields: ['id', 'title', 'content'], // Only fetch the required fields
          filter: { status: { _eq: 'published' } }, // Filter for published pages
        };
        const response = await list(pageName, query);

        if (options?.multiple) {
          setPageData(response.data || []);
        } else {
          setPageData(response.data[0] || null);
        }
      } catch (err) {
        setError(err as Error);
      } finally {
        setLoading(false);
      }
    };

    if (pageName) {
      void fetchPageData();
    }
  }, [list, pageName, options?.multiple]);

  return { pageData, loading, error };
};

export default usePageData;
import React from 'react';
import { 
  Container, 
  DiagramImage, 
  CollapseButton,
  HighlightBar,
  NavigationOverlay
} from './styles';
import { BoundaryOverlay } from '../../styles';
import { Close } from '@mui/icons-material';

interface FullViewProps {
  imageUrl: string;
  currentRow: number;
  onRowChange: (row: number) => void;
  onCollapse: () => void;
  totalRows: number;
  boundaries: { top: number; bottom: number };
}

export const FullView: React.FC<FullViewProps> = ({
  imageUrl,
  currentRow,
  onRowChange,
  onCollapse,
  totalRows,
  boundaries
}) => {
  const handleNavigationClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation(); // Prevent container click
    
    const rect = event.currentTarget.getBoundingClientRect();
    const y = event.clientY - rect.top;
    const height = rect.height;
    
    // First convert click to percentage of total height
    const clickPercentage = (y / height) * 100;
    
    // If click is above boundaries, go to top row
    if (clickPercentage < boundaries.top) {
      onRowChange(totalRows - 1);
      return;
    }
    
    // If click is below boundaries, go to bottom row
    if (clickPercentage > boundaries.bottom) {
      onRowChange(0);
      return;
    }
    
    // Otherwise calculate exact row within boundaries
    const boundedPercentage = (clickPercentage - boundaries.top) / (boundaries.bottom - boundaries.top);
    const clickedRow = totalRows - 1 - Math.floor(boundedPercentage * totalRows);
    onRowChange(clickedRow);
  };

  const handleDoubleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    onCollapse();
  };

  return (
    <Container onClick={onCollapse}>
      <CollapseButton onClick={onCollapse}>
        <Close />
      </CollapseButton>
      
      <NavigationOverlay 
        onClick={handleNavigationClick}
        onDoubleClick={handleDoubleClick}
      >
        <DiagramImage 
          src={imageUrl} 
          alt="Knitting diagram full view"
        />
        <BoundaryOverlay 
          top={boundaries.top}
          bottom={boundaries.bottom}
        />
        <HighlightBar 
          style={{ 
            top: `${boundaries.top + ((boundaries.bottom - boundaries.top) * (totalRows - 1 - currentRow) / totalRows)}%`,
            height: `${(boundaries.bottom - boundaries.top) / totalRows}%` 
          }} 
        />
      </NavigationOverlay>
    </Container>
  );
};
import { getAssetBackendUrl } from '@/utils/getAssetBackendUrl';
import { LinearProgress, styled, Dialog } from '@mui/material';
import { Button } from '@/components/button/button';
import DOMPurify from 'dompurify';
import { FC, useEffect, useMemo, useState } from 'react';
import { Preview } from './components/preview';
import { useDispatch, useSelector } from 'react-redux';
import {
  getResourcesViewDataSelector,
  getPatternViewLoadingSelector,
  getDiagramViewDataSelector,
} from '@/store/selectors/pattern-view.selector';
import {
  clearResourceDataAction,
  getResourceByIdAction,
  getDiagramByIdAction,
} from '@/store/reducers/pattern-view.reducer';
import { PATH } from '@/common/constants/path';
import { t } from 'i18next';
import { ImgVideo } from '@/components/img-video/img-video';
import { ResourceWithStage } from '@/store/types/pattern';
import { BigPageTitle } from '@/components/big-page-title';

const Container = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  padding: '0.5rem',
});

const TitleContainer = styled('div')({
  display: 'flex',
  marginBottom: '1rem',
  justifyContent: 'space-between',
  paddingRight: '3rem',
});

const Title = styled('div')({
  fontSize: '2.5rem',
  textTransform: 'capitalize',
  margin: '0 1rem 0 0',
  textAlign: 'left',
});

const Description = styled('div')({
  fontSize: '1.125',
  margin: '0 0 1rem',
});

const Image = styled(ImgVideo)({
  width: '100%',
  marginBottom: '1rem',
});

const FullscreenDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    margin: 0,
    maxWidth: 'none',
    maxHeight: 'none',
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const FullscreenImage = styled(ImgVideo)({
  '& img': {
    width: 'auto !important',
    height: '100% !important',
    maxWidth: '100%',
    objectFit: 'contain',
    margin: '0 auto',
    display: 'block',
    maxHeight: '100vh',
    minHeight: '100vh',
    minWidth: '100vw',
    cursor: 'zoom-out',
    backgroundColor: '#ffffff',
  }
});

const ClickableImage = styled(Image)({
  cursor: 'zoom-in',
});

export interface ResourceViewProps {
  resourceId: number;
  resourceType: 'resource' | 'diagram';
}

export const ResourceView: FC<ResourceViewProps> = ({ resourceId, resourceType }) => {
  const dispatch = useDispatch();
  const [currentFileId, setCurrentFileId] = useState<string>('');
  const isLoading = useSelector(getPatternViewLoadingSelector);
  const resource = useSelector(getResourcesViewDataSelector);
  const diagram = useSelector(getDiagramViewDataSelector);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isCurrentFileVideo, setIsCurrentFileVideo] = useState(false);

  const content = useMemo(() => {
    switch (resourceType) {
      case 'resource': {
        return resource;
      }
      case 'diagram': {
        return diagram;
      }
    }
  }, [resource, diagram]);

  const [filesTitle, setFilesTitle] = useState<{
    [key: string]: string;
  }>({});

  useEffect(() => {
    loadResource(resourceId);
    return () => {
      dispatch(clearResourceDataAction());
    };
  }, []);

  useEffect(() => {
    let initialFileId = '';
    switch (resourceType) {
      case 'resource': {
        if ((content as ResourceWithStage)?.files) {
          initialFileId = (content as ResourceWithStage).files![0].file;
        }
        break;
      }
      case 'diagram': {
        initialFileId = diagram?.image || '';
        break;
      }
    }
    setCurrentFileId(initialFileId);
  }, [content]);

  const loadResource = (id: number) => {
    switch (resourceType) {
      case 'resource': {
        dispatch(getResourceByIdAction(id));
        return;
      }
      case 'diagram': {
        dispatch(getDiagramByIdAction(id));
        return;
      }
    }
  };

  const clearDescription = useMemo(() => {
    return DOMPurify.sanitize(resource?.description || '');
  }, [resource?.description]);

  const handleFileChange = (id: string) => {
    setCurrentFileId(id);
  };

  const handleImageClick = () => {
    if (!isCurrentFileVideo) {
      setIsFullscreen(true);
    }
  };

  const handleCloseFullscreen = () => {
    setIsFullscreen(false);
  };

  return isLoading ? (
    <LinearProgress />
  ) : (
    <>
      <BigPageTitle title={content?.name || ''} />
      <Container>
        {resourceType === 'resource' && (
          <>
            <Preview
              files={resource?.files || []}
              filesTitle={filesTitle}
              onChangeFile={handleFileChange}
            />
            {currentFileId && (
              <>
                <ClickableImage
                  src={getAssetBackendUrl(currentFileId)}
                  naturalAspectRatio
                  videoProps={{
                    autoPlay: true,
                    controls: true,
                  }}
                  onClick={handleImageClick}
                  style={{ cursor: isCurrentFileVideo ? 'default' : 'zoom-in' }}
                  onError={() => setIsCurrentFileVideo(true)}
                  onLoad={() => setIsCurrentFileVideo(false)}
                />
                {!isCurrentFileVideo && (
                  <FullscreenDialog
                    open={isFullscreen}
                    onClose={() => setIsFullscreen(false)}
                    onClick={() => setIsFullscreen(false)}
                  >
                    <FullscreenImage
                      src={getAssetBackendUrl(currentFileId)}
                      naturalAspectRatio
                    />
                  </FullscreenDialog>
                )}
                <Description dangerouslySetInnerHTML={{ __html: clearDescription }} />
              </>
            )}
          </>
        )}
        {resourceType === 'diagram' && (
          <>
            {currentFileId && (
              <>
                <ClickableImage
                  src={getAssetBackendUrl(currentFileId)}
                  naturalAspectRatio
                  videoProps={{
                    autoPlay: true,
                    controls: true,
                  }}
                  onClick={handleImageClick}
                  style={{ cursor: isCurrentFileVideo ? 'default' : 'zoom-in' }}
                  onError={() => setIsCurrentFileVideo(true)}
                  onLoad={() => setIsCurrentFileVideo(false)}
                />
                {!isCurrentFileVideo && (
                  <FullscreenDialog
                    open={isFullscreen}
                    onClose={() => setIsFullscreen(false)}
                    onClick={() => setIsFullscreen(false)}
                  >
                    <FullscreenImage
                      src={getAssetBackendUrl(currentFileId)}
                      naturalAspectRatio
                    />
                  </FullscreenDialog>
                )}
              </>
            )}
            <br />
          </>
        )}
      </Container>
    </>
  );
};

import { Errors } from '@/common/constants/errors';
import { Collections } from '@/common/interfaces/collection.interface';
import { useSelector } from 'react-redux';
import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { ApiType } from '../../services/api';
import { TransportResponse } from '@directus/sdk';
import { DirectusFields } from '@/common/constants/directus-fields';
import {getSearchbarDataSelector} from '@/store/selectors/system.selector';

import {
  getDesignersAction,
  getDesignersErrorAction,
  getDesignersSuccessAction,
} from '../reducers/designers.reducer';
import { DesignerWithStage } from '../types/designer';
import { getDesignersFilterSelector, getDesignersSortSelector } from '../selectors/designers.selector';

function* getDesignersRequest(api: ApiType) {
    const searchbarValue: string = yield select(getSearchbarDataSelector);
    
    const designersFilter:object = yield select(getDesignersFilterSelector);
    const designersSort:string[] = yield select(getDesignersSortSelector);
    let filter = {
      role: {
        name: {
          _eq: 'PATTERN DESIGNER',
        },
      },
      status: {
        _eq: 'active',
      },
      plan_active: {
        _eq: true,
      },
      avatar: {
        _nnull: true,
      },
      date_created: {
        _gte: '2024-09-01',
      },
    };
    if (designersFilter && Object.keys(designersFilter).length) {
        filter = {...filter, ...designersFilter};
    }
    
    

    let requestOptions:any = {
        fields: DirectusFields.Designer,
        limit: 60,
        filter: filter,
      };

      requestOptions['sort'] = designersSort;
  
      if (searchbarValue) {
        requestOptions['search'] = searchbarValue;
      }
  
    try {
    const response: TransportResponse<DesignerWithStage[]> = yield call(
      api.getCollectionWithoutRefresh,
      Collections.Designers,
      requestOptions,
    );
    if (!response.data) {
      throw new Error(Errors.Default);
    }
    yield put(getDesignersSuccessAction(response.data));
  } catch (error: any) {
    yield put(getDesignersErrorAction());
  }
}

export const designersSaga = function* (api: ApiType) {
  yield all([takeLatest(getDesignersAction.type, getDesignersRequest, api)]);
};

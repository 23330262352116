import { UpdateUserDto } from '@/common/interfaces/api/update-user.dto';
import { Role, RoleName } from '@/common/interfaces/role.interface';
import { User } from '@/common/interfaces/user.interface';
import { getCurrentUserRoleName } from '@/utils/getCurrentUserRole';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BoughtPatternWithStage, PatternWithStage, ResourceWithStage } from '../types/pattern';
import { CreateUserPayload, OrderWithStage, UploadAvatarPayload, UploadLogoPayload, GetUserByReferralCodePayload } from '../types/user';
import { YarnWithStage } from '../types/yarniverse';
import { logoutSuccessAction } from './authorization.reducer';

export interface UserState {
  isLoading: boolean;
  error: string | null;
  roles: Role[] | [];
  currentUser: User | null;
  patterns: PatternWithStage[];
  boughtPatterns: BoughtPatternWithStage[];
  resources: ResourceWithStage[];
  yarns: YarnWithStage[];
  orders: OrderWithStage[];
  currentRoleName: RoleName | null;
  profileUser: User | null;
}

const initialState: UserState = {
  isLoading: false,
  error: null,
  roles: [],
  currentUser: null,
  patterns: [],
  boughtPatterns: [],
  resources: [],
  orders: [],
  yarns: [],
  currentRoleName: null,
  profileUser: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    createUserAction(state, _: PayloadAction<CreateUserPayload>) {
      state.isLoading = true;
      state.error = null;
    },
    createUserSuccessAction(state) {
      state.isLoading = false;
      state.error = null;
    },
    createUserErrorAction(state) {
      state.isLoading = false;
    },

    // Get Roles
    getRolesAction: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    getRolesSuccessAction: (state, action: PayloadAction<Role[]>) => {
      state.isLoading = false;
      state.error = null;
      state.roles = action.payload;
    },
    getRolesErrorAction: (state) => {
      state.isLoading = false;
    },

    // Get current user
    getCurrentUserAction: (state) => {
      state.isLoading = true;
    },
    getCurrentUserSuccessAction: (state, action: PayloadAction<User>) => {
      state.isLoading = false;
      state.currentUser = action.payload;
      state.currentRoleName = getCurrentUserRoleName(action.payload.role?.name);
    },
    getCurrentUserErrorAction: (state) => {
      state.isLoading = false;
    },

    // Set current user role
    setCurrentRoleNameAction: (state, action: PayloadAction<RoleName>) => {
      state.currentRoleName = action.payload;
    },

    // Upload avatar
    uploadAvatarAction: (state, _: PayloadAction<UploadAvatarPayload>) => {
      state.isLoading = true;
    },
    uploadAvatarSuccessAction: (state, action: PayloadAction<User>) => {
      state.isLoading = false;
      state.currentUser = action.payload;
    },
    uploadAvatarErrorAction: (state) => {
      state.isLoading = false;
    },

    // Get user pattern
    getUserPatternsAction: (state, _: PayloadAction<string>) => {
      state.isLoading = true;
    },
    getUserPatternsSuccessAction: (state, action: PayloadAction<PatternWithStage[]>) => {
      console.log('getUserPatternsSuccessAction');
      state.patterns = action.payload;
      state.isLoading = false;
    },
    getBoughtPatternsAction: (state, _: PayloadAction<string>) => {
      state.isLoading = true;
    },
    getBoughtPatternsSuccessAction: (state, action: PayloadAction<BoughtPatternWithStage[]>) => {
      console.log('getBoughtPatternsSuccessAction');
      state.boughtPatterns = action.payload;
      state.isLoading = false;
    },
    getUserPatternsErrorAction: (state) => {
      state.isLoading = false;
    },

    // Get user resources
    getUserResourcesAction: (state, _: PayloadAction<string>) => {
      state.isLoading = true;
    },
    getUserResourcesSuccessAction: (state, action: PayloadAction<ResourceWithStage[]>) => {
      state.resources = action.payload;
      state.isLoading = false;
    },
    getUserResourcesErrorAction: (state) => {
      state.isLoading = false;
    },

    // Get user yarns
    getUserYarnsAction: (state, _: PayloadAction<string>) => {
      state.isLoading = true;
    },
    getUserYarnsSuccessAction: (state, action: PayloadAction<YarnWithStage[]>) => {
      state.yarns = action.payload;
      state.isLoading = false;
    },

    // Update user
    updateUserAction: (state, _: PayloadAction<UpdateUserDto>) => {
      state.isLoading = true;
    },
    updateUserSuccessAction: (state, action: PayloadAction<User>) => {
      state.currentUser = action.payload;
      state.isLoading = false;
    },
    updateUserErrorAction: (state) => {
      state.isLoading = false;
    },

    // Get current user order
    getUserOrdersAction: (state) => {
      state.isLoading = true;
    },
    getUserOrdersSuccessAction: (state, action: PayloadAction<OrderWithStage[]>) => {
      state.isLoading = false;
      state.orders = action.payload;
    },
    getUserOrdersErrorAction: (state) => {
      state.isLoading = false;
    },

    // Get user by id
    getUserByIdAction: (state, _: PayloadAction<string>) => {
      state.isLoading = true;
    },
    getUserByIdSuccessAction: (state, action: PayloadAction<User>) => {
      state.isLoading = false;
      state.profileUser = action.payload;
    },

    // Clear profile user
    clearProfileUserAction: (state) => {
      state.profileUser = null;
    },

    // Clear
    clearUserAction: () => {
      return initialState;
    },

    // error action
    userErrorAction: (state) => {
      state.isLoading = false;
    },

    // Upload logo
    uploadLogoAction: (state, _: PayloadAction<UploadLogoPayload>) => {
      state.isLoading = true;
    },
    uploadLogoSuccessAction: (state, action: PayloadAction<User>) => {
      state.isLoading = false;
      state.currentUser = action.payload;
    },
    uploadLogoErrorAction: (state) => {
      state.isLoading = false;
    },

    getUserByReferralCodeAction: (state, _: PayloadAction<GetUserByReferralCodePayload>) => {
      state.isLoading = true;
    },
    getUserByReferralCodeSuccessAction: (state, action: PayloadAction<User>) => {
      state.isLoading = false;
      state.profileUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutSuccessAction, () => initialState);
  },
});

export const {
  createUserAction,
  createUserSuccessAction,
  createUserErrorAction,
  getRolesAction,
  getRolesSuccessAction,
  getRolesErrorAction,
  getCurrentUserAction,
  getCurrentUserSuccessAction,
  getCurrentUserErrorAction,
  uploadAvatarAction,
  uploadAvatarSuccessAction,
  uploadAvatarErrorAction,
  getUserPatternsAction,
  getBoughtPatternsAction,
  getUserPatternsSuccessAction,
  getBoughtPatternsSuccessAction,
  getUserPatternsErrorAction,
  updateUserAction,
  updateUserSuccessAction,
  updateUserErrorAction,
  getUserOrdersAction,
  getUserOrdersSuccessAction,
  getUserOrdersErrorAction,
  getUserResourcesAction,
  getUserResourcesSuccessAction,
  getUserResourcesErrorAction,
  getUserYarnsAction,
  getUserYarnsSuccessAction,
  userErrorAction,
  setCurrentRoleNameAction,
  getUserByIdAction,
  getUserByIdSuccessAction,
  clearUserAction,
  clearProfileUserAction,
  uploadLogoAction,
  uploadLogoSuccessAction,
  uploadLogoErrorAction,
  getUserByReferralCodeAction,
  getUserByReferralCodeSuccessAction,
} = userSlice.actions;

export const userReducer = userSlice.reducer;

import usePageData from "@/hooks/usePageData";
import React from "react";

const TermsAndConditionsPage: React.FC = () => {
    
    const { pageData, loading } = usePageData('terms_conditions', { multiple: true });

    console.log(pageData);

    return (
        <>
           {!loading && pageData && Array.isArray(pageData) && <div className="about-page">
                <div className="about-container">
                    <h1 className="about-title">{pageData[0]?.title}</h1>
                    <div dangerouslySetInnerHTML={{ __html: pageData[0]?.content || '' }} />
                    <h1 className="about-title">{pageData[1]?.title}</h1>
                    <div dangerouslySetInnerHTML={{ __html: pageData[1]?.content || '' }} />
                    <h1 className="about-title">{pageData[2]?.title}</h1>
                    <div dangerouslySetInnerHTML={{ __html: pageData[2]?.content || '' }} />
                    <h1 className="about-title">{pageData[3]?.title}</h1>
                    <div dangerouslySetInnerHTML={{ __html: pageData[3]?.content || '' }} />
                    <h1 className="about-title">{pageData[4]?.title}</h1>
                    <div dangerouslySetInnerHTML={{ __html: pageData[4]?.content || '' }} />
                    <h1 className="about-title">{pageData[5]?.title}</h1>
                    <div dangerouslySetInnerHTML={{ __html: pageData[5]?.content || '' }} />
                </div>
                <div>
                    <span className="vertical-line"></span>
                </div>
                <div className="profile">
                </div>
            </div>}

        </>
    )
};

export default TermsAndConditionsPage;
import { Collections } from '@/common/interfaces/collection.interface';
import { QueryMany } from '@directus/sdk';
import { Store } from '@mui/icons-material';

export const DirectusFields = {
  Pattern: [
    '*, images.*',
    '*, user_created.username',
    '*, user_created.id',
    '*, user_created.display_name',
    '*, user_created.first_name',
    '*, user_created.last_name',
    '*, pattern_steps.*',
    '*, pattern_steps.relevant_sizes.*',
    '*, pattern_steps.ressources.resources_id.*',
    '*, pattern_steps.ressources.resources_id.files.*',
    '*, pattern_steps.step_diagrams.*',
    '*, hand_work_technique.hand_work_technique_id.*',
    '*, language.language_id.*',
    '*, craft.*',
    '*, masketett.masketett_id.*',
    '*, passer_til.passer_til_id.*',
    '*, type_of_project.type_of_project_id.*',
    '*, unit_measurement.*',
    '*, needle.*',
    '*, needle.needle_size.*',
    '*, needle.needle_type.*',
    '*, needle.cable_length.*',
    '*, alternatives.*',
    '*, alternatives.alternativ_items.*',
    '*, alternatives.alternativ_items.yarn.*',
    '*, alternatives.alternativ_items.color.*',
    '*, alternatives.alternativ_items.quantity_with_size.*',
    '*, main_image.*',
    '*, available_sizes.size_chart_id.*',
    '*, design_elements.design_element_item_id.*',
    '*, pattern_size_model',
  ],
  PatternSearch: ['id', 'name', 'main_image.*', 'user_created.*', 'pattern_price.*'],
  Resource: [
    '*, Craft.*',
    'ResourceContentType.resource_content_type_id.*, ResourceType.resource_type_id.*',
    'files.*',
    'user_created.username',
    'user_created.id',
    'user_created.display_name',
  ],
  ResourceAll: [
    '*, Craft.*',
    'ResourceContentType.resource_content_type_id.*, ResourceType.resource_type_id.*',
    'files.file.*',
  ],
  PatternBasket: ['id', 'name', 'main_image.image', 'price', 'user_created.username', 'prices.*'],
  CurrentUser: 'fields[]=*&fields[]=country.*&fields[]=role.*',
  Order: [
    '*, currency.*',
    'pattern_ref.user_created.*',
    'pattern_ref.main_image.*',
    'pattern_ref.name',
    'pattern_ref.id',
  ],

  PatternSteps: [
    'pattern_steps.*',
    'pattern_steps.relevant_sizes.*',
    'pattern_steps.ressources.resources_id.*',
    'pattern_steps.ressources.resources_id.files.*',
    'pattern_steps.ressources.resources_id.ResourceContentType.resource_content_type_id.*, pattern_steps.ressources.resources_id.ResourceType.resource_type_id.*',
    'pattern_steps.step_diagrams.*',
  ],
  YarnFiber: ['fiber.fiber_id.*.*'],
  Yarn: [
    '*',
    'user_created.*',
    'main_color.*',
    'prices.*',
    'prices.currency.*',
    'images.*',
    'colors.*',
    'gauge.masketett_id.*',
    'fiber.fiber_id.*',
    'fiber.fiber_id.country.*',
    'fiber.fiber_id.type.*',
    'wash_and_care.wash_and_care_id.*',
    'needle_size.needle_size_id.*',
    'amount_weight_unit.name',
    'amount_lenght_unit.name',
    'country_of_origin.*',
    'available_country.*',
    'yarn_manufacturer.*',
    'yarn_manufacturer.user_created.*',
    'thread_construction.*',
    'texture.*',
    'thread_construction.*',
  ],

  PatternProfile: [
    'id',
    'status',
    'name',
    'price',
    'images.image',
    'main_image.image',
    'user_created.display_name',
    'user_created.username',
    'user_created.id',
  ],
  BoughtPatternProfile: [
    'id',
    'status',
    'amount',
    'user_created',
    'payment_ref',
    'pattern.*',
    'pattern.main_image.*',
    'pattern.available_sizes.size_chart_id.*',
    'pattern.user_created.display_name',
    'pattern.user_created.username',
    'pattern.user_created.id',
  ],
  YarnProfile: ['id', 'status', 'name', 'colors.*', 'main_color.*', 'prices.*'],
  Measurements: [
    '*, measurement_values.*',
    'measurement_values.*',
    'measurement_values.size_chart.*',
  ],
  YarnSearch: ['id', 'name', 'main_image.*', 'user_created.*', 'yarn_price.*'],
  ResourceSearch: ['id', 'name', 'files.file', 'user_created.*'],
  YarnManufacturer: [
    '*',
    'available_country.countries_id.*',
    'shipment_methods.currency.*',
    'shipment_methods.shipment_method_id.*',
    'shipment_methods.price',
    'shipment_methods.id',
    'main_image.*',
  ],
  WebPages: ['*, page_items.*', '*, page_items.web_page_items_id.*'],
  Users: ['*, country.*', '*, role.*'],
  Needle: ['*, images.*', 'main_image.*'],
  Designer: ['*, images.*', 'main_image.*'],
  YarnBasket: [
    'id',
    'name',
    'yarn_color.*',
    'yarn_color.image.*',
    'yarn_color.yarn.*',
    'yarn_color.yarn.user_created.username',
  ],
  Store: [
    '*',
    'inventory.inventory_id.*',
    'inventory.inventory_id.product.products_id.*',
    'inventory.inventory_id.product.products_id.yarn_color.*',
    'inventory.inventory_id.product.products_id.yarn_color.yarn.*',
    'supplier.Yarn_manufacturer_id.*',
  ],
  Product: ['*', 'yarn_color.*', 'yarn_color.yarn.*', 'yarn_color.image.*'],
};

export const getQueryParamsForCollections = (type: Collections): QueryMany<unknown> => {
  const queryParams: QueryMany<unknown> = {};

  switch (type) {
    case Collections.Projects: {
      queryParams.sort = ['name' as never];
      break;
    }
    case Collections.Suitable: {
      queryParams.sort = ['sort' as never];
      break;
    }
    case Collections.Languages: {
      queryParams.sort = ['name' as never];
      break;
    }
    case Collections.Gauge: {
      queryParams.sort = ['sort' as never];
      break;
    }
    case Collections.AvailableSize: {
      queryParams.sort = ['sort' as never];
      break;
    }
    case Collections.Yarn: {
      queryParams.fields = '*,colors.*';
      break;
    }
    case Collections.Resources: {
      queryParams.sort = ['-date_created' as never];
      queryParams.fields = DirectusFields.Resource;
      break;
    }
  }

  return queryParams;
};

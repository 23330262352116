import usePageData from "@/hooks/usePageData";
import React from "react";

const PrivacyPolicyPage: React.FC = () => {

    const { pageData, loading } = usePageData('privacy_policy');

    return (
        <>
           {! loading && <div className="about-page">
                <div className="about-container">
                    <h1 className="about-title">{!Array.isArray(pageData) && pageData?.title}</h1>
                    {/* <p className="about-subheading">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
                    {/* <div>
                        <img className="image" src={Aboutusyarn} alt="Aboutusyarn" />
                    </div> */}
                    <div dangerouslySetInnerHTML={{ __html: !Array.isArray(pageData) ? pageData?.content || '' : '' }} />
                </div>
                <div>
                    <span className="vertical-line"></span>
                </div>
                <div className="profile">
                    {/* <img className="profile-picture" src={Aboutusprofile} alt="Aboutusprofile" />
                    <h2>Mark Jacobs</h2> */}
                </div>
            </div>}

        </>
    )
};

export default PrivacyPolicyPage;
import React, { useState } from 'react';
import { Container } from './styles';
import { FocusedView } from './components/focused-view';
import { FullView } from './components/full-view';
import { getAssetBackendUrl } from '@/utils/getAssetBackendUrl';
import { RowEditor } from './components/row-editor';

interface DiagramViewerProps {
  imageUrl: string;
  name: string;
  initialRow?: number;
  isEditingRows?: boolean;
  onEditRowsChange?: (isEditing: boolean) => void;
}

export const DiagramViewer: React.FC<DiagramViewerProps> = ({
  imageUrl,
  name,
  initialRow = 0,
  isEditingRows: externalIsEditingRows,
  onEditRowsChange
}) => {
  const [isFocusedExpanded, setIsFocusedExpanded] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentRow, setCurrentRow] = useState(initialRow);
  const [totalRows, setTotalRows] = useState(1);
  const [internalIsEditingRows, setInternalIsEditingRows] = useState(false);
  const [boundaries, setBoundaries] = useState({ 
    top: 0, 
    bottom: 99.9 // Slightly less than 100 to ensure line is visible
  });

  // Use external state if provided, otherwise use internal state
  const isEditingRows = externalIsEditingRows ?? internalIsEditingRows;
  const setIsEditingRows = (value: boolean) => {
    if (onEditRowsChange) {
      onEditRowsChange(value);
    } else {
      setInternalIsEditingRows(value);
    }
  };

  const handleRowChange = (newRow: number) => {
    const boundedRow = Math.max(0, Math.min(newRow, totalRows - 1));
    setCurrentRow(boundedRow);
  };

  const toggleView = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleFocusedExpansion = () => {
    setIsFocusedExpanded(!isFocusedExpanded);
  };

  const handleRowCountChange = (newCount: number) => {
    setTotalRows(newCount);
  };

  const handleBoundariesChange = (newBoundaries: { top: number; bottom: number }) => {
    setBoundaries(newBoundaries);
  };

  const processedImageUrl = getAssetBackendUrl(imageUrl) || '';

  return (
    <Container>
      <FocusedView
        imageUrl={processedImageUrl}
        currentRow={currentRow}
        onRowChange={handleRowChange}
        onExpand={toggleView}
        onFocusedExpand={toggleFocusedExpansion}
        isExpanded={isFocusedExpanded}
        totalRows={totalRows}
        isHidden={isExpanded}
        isFocusedExpanded={isFocusedExpanded}
        onEditRows={() => setIsEditingRows(true)}
        boundaries={boundaries}
      />
      {/* Only render FullView after first expansion */}
      {isExpanded && (
        <FullView
          imageUrl={processedImageUrl}
          currentRow={currentRow}
          onRowChange={handleRowChange}
          onCollapse={toggleView}
          totalRows={totalRows}
          boundaries={boundaries}
        />
      )}
      {isEditingRows && (
        <RowEditor
          imageUrl={processedImageUrl}
          totalRows={totalRows}
          onRowCountChange={handleRowCountChange}
          onBoundariesChange={handleBoundariesChange}
          boundaries={boundaries}
          onClose={() => setIsEditingRows(false)}
        />
      )}
    </Container>
  );
};

import React, { useState, useRef, useEffect } from 'react';
import { 
  Container, 
  NavigationOverlay,
  DiagramImage,
  RowLines,
  CollapseButton,
  RowCountInput,
  BoundaryLine,
  InputContainer,
  InputLabel,
  InstructionText
} from './styles';
import { BoundaryOverlay } from '../../styles';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface RowEditorProps {
  imageUrl: string;
  totalRows: number;
  onRowCountChange: (count: number) => void;
  onClose: () => void;
  boundaries: { top: number; bottom: number };
  onBoundariesChange: (boundaries: { top: number; bottom: number }) => void;
}

interface Boundaries {
  top: number;
  bottom: number;
}

export const RowEditor: React.FC<RowEditorProps> = ({
  imageUrl,
  totalRows,
  onRowCountChange,
  onClose,
  boundaries,
  onBoundariesChange
}) => {
  const [inputValue, setInputValue] = useState(totalRows.toString());
  const [localBoundaries, setLocalBoundaries] = useState(boundaries);
  const [dragging, setDragging] = useState<'top' | 'bottom' | null>(null);
  const overlayRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const handleRowCountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    
    const parsedValue = parseInt(value);
    if (!isNaN(parsedValue)) {
      if (parsedValue <= 0) {
        setInputValue('1');
        onRowCountChange(1);
      } else if (parsedValue > 999) {
        setInputValue('999');
        onRowCountChange(999);
      } else {
        onRowCountChange(parsedValue);
        setInputValue(parsedValue.toString());
      }
    }
  };

  useEffect(() => {
    if (!dragging) return;

    const handleGlobalMouseMove = (event: MouseEvent) => {
      if (!overlayRef.current) return;

      const rect = overlayRef.current.getBoundingClientRect();
      const lineHeight = 1; // Match the height from styles
      const lineHeightPercentage = (lineHeight / rect.height) * 100;

      // Calculate percentage but account for line height
      const percentage = ((event.clientY - rect.top) / rect.height) * 100;
      
      // Adjust bounds based on whether it's top or bottom boundary
      const boundedPercentage = dragging === 'top' 
        ? Math.max(0, Math.min(100, percentage))
        : Math.max(0, Math.min(100 - lineHeightPercentage, percentage));

      setLocalBoundaries(prev => {
        if (dragging === 'top') {
          if (boundedPercentage >= prev.bottom) return prev;
          return { ...prev, top: boundedPercentage };
        } else {
          if (boundedPercentage <= prev.top) return prev;
          return { ...prev, bottom: boundedPercentage };
        }
      });
    };

    const handleGlobalMouseUp = () => {
      setDragging(null);
    };

    window.addEventListener('mousemove', handleGlobalMouseMove);
    window.addEventListener('mouseup', handleGlobalMouseUp);

    return () => {
      window.removeEventListener('mousemove', handleGlobalMouseMove);
      window.removeEventListener('mouseup', handleGlobalMouseUp);
    };
  }, [dragging]);

  const handleMouseDown = (boundary: 'top' | 'bottom') => (event: React.MouseEvent) => {
    event.preventDefault();
    setDragging(boundary);
  };

  useEffect(() => {
    onBoundariesChange(localBoundaries);
  }, [localBoundaries]);

  return (
    <Container>
      <CollapseButton onClick={onClose}>
        <Close />
      </CollapseButton>

      <InputContainer>
        <InputLabel>{t('knitmode.rowCount')}</InputLabel>
        <RowCountInput
          type="number"
          min="1"
          max="999"
          value={inputValue}
          onChange={handleRowCountChange}
          placeholder={t('knitmode.rowCountPlaceholder')}
        />
        <InstructionText>
          {t('knitmode.dragBoundaries')}
        </InstructionText>
      </InputContainer>
      
      <NavigationOverlay ref={overlayRef}>
        <DiagramImage 
          src={imageUrl} 
          alt="Knitting diagram row editor"
        />
        <BoundaryOverlay 
          top={localBoundaries.top}
          bottom={localBoundaries.bottom}
        />
        <RowLines 
          rowCount={parseInt(inputValue) || totalRows}
          top={localBoundaries.top}
          bottom={localBoundaries.bottom}
        />
        <BoundaryLine 
          style={{ top: `${localBoundaries.top}%` }}
          onMouseDown={handleMouseDown('top')}
          $dragging={dragging === 'top'}
          $position="top"
        />
        <BoundaryLine 
          style={{ top: `${localBoundaries.bottom}%` }}
          onMouseDown={handleMouseDown('bottom')}
          $dragging={dragging === 'bottom'}
          $position="bottom"
        />
      </NavigationOverlay>
    </Container>
  );
};